import { Link } from "react-router-dom";

const Header = ({ contectUsSectionRef }) => {
  const handleContectUs = () => {
    contectUsSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <section className="self-stretch bg-whitesmoke flex flex-col items-end justify-start max-w-full text-center text-5xl-5 text-black-900 font-inter">
      <header className="self-stretch [background:linear-gradient(184.52deg,_rgba(255,_255,_255,_0.08),_rgba(255,_255,_255,_0))] flex flex-row items-end justify-between pt-3 px-[53px] pb-[12.4px] top-[0] z-[99] sticky gap-[20px] text-left text-sm text-gray-100 font-inter mq750:pl-[26px] mq750:pr-[26px] mq750:box-border">
        <div className="h-[41.8px] w-28 flex flex-col items-start justify-end pt-0 px-0 pb-[7.8px] box-border">
          <Link to={"/"}>
            <img
              className="self-stretch h-[34px] relative max-w-full overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/logo-1.svg"
            />
          </Link>
        </div>
        <Link
          to={"/"}
          className="uppercase no-underline text-[14px] text-[#FAFAFA] font-bold"
        >
          <div className="rounded-13xl [background:linear-gradient(91.19deg,_#06ddf9,_#1593a3)] flex flex-row items-start justify-start pt-[16.3px] px-[23px] pb-[15.7px] whitespace-nowrap">
            Get app
          </div>
        </Link>
      </header>
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-center pt-[107.6px] px-5 pb-[108.4px] box-border bg-[url('/public/background.png')] bg-cover bg-no-repeat bg-[top] max-w-full mq750:pt-[70px] mq750:pb-[70px] mq750:box-border">
        <img
          className="h-[700px] w-[1440px] relative object-cover hidden max-w-full"
          alt=""
          src="/background.png"
        />
        <div className="w-[1006.4px] flex flex-col items-center justify-center py-[70.3px] px-0 box-border gap-[42.9px] max-w-full z-[1] mq750:gap-[21px_42.9px] mq750:pt-[46px] mq750:pb-[46px] mq750:box-border">
          <div className="self-stretch flex flex-col items-center justify-center gap-[12.2px]">
            <div className="self-stretch flex flex-col items-center justify-center pt-0 px-0 pb-0 gap-[0px]">
              <div className="w-[146px] rounded-[6.12px] bg-neutral-100 box-border hidden flex-row items-center justify-center py-[6.1px] px-[24.5px] border-[1.5px] border-solid border-black-900">
                <div className="h-10 flex-1 flex flex-row items-center justify-start py-0 px-[15px] box-border gap-[6.1px]">
                  <img
                    className="h-[27.6px] w-[27.6px] relative object-cover hidden"
                    alt=""
                    src="/iconclose@2x.png"
                  />
                  <div className="h-[12.2px] w-[12.2px] relative rounded-[153.07px] bg-teal-500 hidden" />
                  <div className="self-stretch flex-1 relative leading-[39.8px] font-semibold mq450:text-xl mq450:leading-[32px]">
                    SALE
                  </div>
                  <img
                    className="h-[27.6px] w-[27.6px] relative object-cover hidden"
                    alt=""
                    src="/iconclose@2x.png"
                  />
                </div>
              </div>
              <h1 className="m-0 self-stretch relative text-61xl tracking-[-1.53px] leading-[95px] font-semibold font-inherit text-darkturquoise mq450:text-5xl mq450:leading-[38px] mq1050:text-21xl mq1050:leading-[57px]">
                Prison Mail Service
              </h1>
            </div>
            <div className="self-stretch relative text-5xl leading-[45.92px] text-neutral-100 mq450:text-lgi mq450:leading-[37px]">
              The Prison Mail Service is a low-cost, user-friendly way for
              families and their incarcerated loved ones to stay in touch.
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[6.1px] text-left text-5xl text-neutral-100 border-b-[1.5px] border-solid border-neutral-100">
            <div className="flex flex-row items-center justify-start gap-[9.2px]">
              <img
                className="h-[36.7px] w-[36.7px] relative hidden"
                alt=""
                src="/iconarrowright.svg"
              />
              <div
                onClick={handleContectUs}
                className="relative cursor-pointer no-underline tracking-[-0.61px] leading-[49px] text-[#FFFFFF] font-semibold min-w-[125px]"
              >
                Contact Us
              </div>
            </div>
            <img
              className="h-[36.7px] w-[36.7px] relative hidden"
              alt=""
              src="/iconarrowright.svg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
