import { useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "./Loader";

const ContactUs = ({contectUsSectionRef}) => {
  const [btnLoader, setBtnLoader] = useState(false);
  const schema = yup.object().shape({
    name: yup.string().trim().required("Name is required!"),
    phone: yup
      .string()
      .trim()
      .required("Phone is required!")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Phone must be at least 10 characters")
      .max(10, "Phone must be at most 10 characters"),
    email: yup.string().trim().email().required("Email is required!"),
  });
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  async function CreateUserAPI(data) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        phone: +data.phone,
        message: data.message || "",
      }),
    };
    const response = await fetch(
      "https://ip80ocapt8.execute-api.us-east-1.amazonaws.com/dev/v1/enquiry",
      requestOptions
    );
    console.log("add user:", response);
    return response;
  }
  const onSubmit = async (data) => {
    console.log("Data:", data);
    try {
      setBtnLoader(true);
      const response = await CreateUserAPI(data);
      console.log("response:", response);
      if (!response.ok) {
        setBtnLoader(false);
        toast.error("Something went wrong! Try Again");
      } else {
        setBtnLoader(false);
        reset();
        toast.success("We will reach you after some time!");
      }
    } catch (e) {
      console.log(e);
      setBtnLoader(false);
      toast.error("Unable to connect, try again!");
    }
  };
  return (
    <form
    id="contectUs"
    ref={contectUsSectionRef}
      className="self-stretch flex flex-row items-start justify-center py-0 md:px-5 box-border max-w-full text-left text-lg text-gray-200 font-other-18-20-b"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="self-stretch w-[660px] rounded-15xl bg-neutral-100 shadow-[0px_54px_54px_rgba(74,_58,_255,_0.02),_1px_4px_104px_rgba(20,_20,_43,_0.04),_0px_98px_66px_rgba(19,_18,_66,_0.02)] overflow-hidden shrink-0 flex flex-col items-start justify-start py-9 px-8 md:px-12 box-border gap-[74px] max-w-full mq450:gap-[18px_74px] mq450:pt-5 mq450:pb-5 mq450:box-border mq750:gap-[37px_74px] mq750:pl-6 mq750:pr-6 mq750:box-border mq1050:pt-[23px] mq1050:pb-[23px] mq1050:box-border">
        <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[24px]">
          <div className="flex items-center justify-center mx-auto">
            <p className=" text-[24px] font-bold">Contact Us</p>
          </div>
          <div className="relative self-stretch flex flex-col items-start justify-start gap-[17.5px]">
            <b className="relative leading-[20px] inline-block min-w-[78px]">
              Name
            </b>
            <input
              className="bg-neutral-100 h-[64px] w-auto self-stretch rounded-27xl shadow-[0px_2px_11px_rgba(69,_65,_164,_0.06),_0px_4px_10px_rgba(31,_37,_89,_0.07)] box-border flex flex-row items-start justify-start pt-[28.4px] px-5 pb-7 font-other-18-20-b font-medium text-lg text-slategray border-[1px] border-solid border-ghostwhite focus:outline-[#06DDF9]"
              type="text"
              placeholder="Enter your name"
              {...register("name")}
            />
            <img
              className="absolute right-8 bottom-5 h-[25.8px] w-[20.3px]"
              alt=""
              src="/group-41.svg"
            />
          </div>
          <div className="relative self-stretch flex flex-col items-start justify-start gap-[17.5px]">
            <b className="relative leading-[20px] inline-block min-w-[78px]">
              Email
            </b>
            <input
              className="bg-neutral-100 h-[64px] w-auto self-stretch rounded-27xl shadow-[0px_2px_11px_rgba(69,_65,_164,_0.06),_0px_4px_10px_rgba(31,_37,_89,_0.07)] box-border flex flex-row items-start justify-start pt-[28.4px] px-5 pb-7 font-other-18-20-b font-medium text-lg text-slategray border-[1px] border-solid border-ghostwhite focus:outline-[#06DDF9]"
              type="text"
              placeholder="Enter email address"
              {...register("email")}
            />
            <img
              className="absolute right-8 bottom-5 h-[25.8px] w-[20.3px]"
              alt=""
              src="/group-1.svg"
            />
          </div>
          <div className="relative self-stretch flex flex-col items-start justify-start gap-[17.5px]">
            <b className="relative leading-[20px] inline-block min-w-[78px]">
              Phone Number
            </b>
            <input
              className="bg-neutral-100 h-[64px] w-auto self-stretch rounded-27xl shadow-[0px_2px_11px_rgba(69,_65,_164,_0.06),_0px_4px_10px_rgba(31,_37,_89,_0.07)] box-border flex flex-row items-start justify-start pt-[28.4px] px-5 pb-7 font-other-18-20-b font-medium text-lg text-slategray border-[1px] border-solid border-ghostwhite focus:outline-[#06DDF9]"
              placeholder="(123) 456-7890"
              type="tel"
              {...register("phone")}
            />
            <img
              className="absolute right-8 bottom-5 h-[25.8px] w-[20.3px]"
              alt=""
              src="/group-2.svg"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[17.5px]">
          <b className="relative leading-[20px] inline-block min-w-[78px]">
            Message
          </b>
          <textarea
            className="bg-neutral-100 h-[168px] w-auto self-stretch rounded-xl shadow-[0px_2px_6px_rgba(19,_18,_66,_0.07)] box-border flex flex-row items-start justify-start pt-[28.4px] px-5 pb-7 font-other-18-20-b font-medium text-lg text-slategray border-[1px] border-solid border-ghostwhite focus:outline-[#06DDF9]"
            placeholder="You can type your message here...."
            {...register("message")}
            rows={8}
            cols={28}
          />
        </div>
        <div className="self-stretch flex flex-row items-start justify-center">
          <button
            className="cursor-pointer [border:none] py-5 px-[49px] bg-darkturquoise rounded-67xl shadow-[0px_8px_22px_rgba(74,_58,_255,_0.26)] flex flex-row items-start justify-start whitespace-nowrap hover:bg-cadetblue mq450:pl-5 mq450:pr-5 mq450:box-border"
            disabled={btnLoader}
          >
            {btnLoader && <Loader />}
            {!btnLoader && (
              <b className="relative text-lg leading-[20px] inline-block font-other-18-20-b text-neutral-100 text-center">
                Submit
              </b>
            )}
          </button>
        </div>
      </div>
      <Toaster />
    </form>
  );
};

export default ContactUs;
