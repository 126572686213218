import appScreenshot from "../assets/images/appScreenshot.svg";
import all_integrations from "../assets/images/all_integrations.svg";
import shild from "../assets/icons/shield.svg";
import monitor from "../assets/icons/monitor.svg";
import lifeBuoy from "../assets/icons/life-buoy.svg";
import fileText from "../assets/icons/file-text.svg";
import creditCard from "../assets/icons/credit-card.svg";
import checkCircle from "../assets/icons/check-circle.svg";

const FrameComponent1 = () => {
  return (
    <div className="self-stretch flex flex-col justify-start gap-[40px] max-w-full text-center text-29xl text-black1 font-inter mq750:gap-[20px_40px]">
      <div className="self-stretch flex flex-col items-end justify-start gap-[40px] max-w-full text-center text-29xl text-black1 font-inter mq750:gap-[20px_40px]">
      <div className="w-[974.6px] h-28 flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
        <div className="self-stretch w-[411.4px] flex flex-col items-end justify-start max-w-full">
          <div className="self-stretch flex flex-row items-start justify-start">
            <h1 className="m-0 w-[247px] relative text-[32px] md:text-[48px] tracking-[-1px] leading-[56px] font-bold font-inherit flex items-center z-[1] mq450:text-10xl mq450:leading-[34px] mq1050:text-19xl mq1050:leading-[45px]">
              <span className="w-full">
                <p className="m-0">&nbsp;</p>
                <p className="m-0">Why ePMS</p>
              </span>
            </h1>
          </div>
          <div className="w-[173.4px] h-[52.5px] flex flex-row items-start justify-start relative mt-[-97.3px] text-2xs-5 text-neutral-100">
            <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex items-center justify-center z-[0]">
              <img
                className="h-full w-full max-w-full overflow-hidden max-h-full object-contain absolute left-[-15px] top-[8px] [transform:scale(2.171)]"
                loading="lazy"
                alt=""
                src="/group.svg"
              />
            </div>
            <b className="h-14 absolute !m-[0] right-[20px] bottom-[8.41px] tracking-[1.5px] leading-[56px] flex items-center justify-center [transform:_rotate(9.6deg)] [transform-origin:0_0] z-[1]">
              Your trust starts here
            </b>
          </div>
        </div>
      </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 text-left text-5xl">
        <div className="flex-1 flex flex-col items-start justify-start pt-[23px] pb-11 pr-[22.4px] pl-6 box-border gap-[29px] min-w-[270px] max-w-full">
          <div className=" flex gap-2 items-center relative tracking-[-0.5px] leading-[28px] min-w-[81px] mq450:text-lgi mq450:leading-[22px]">
            <img className=" w-7 h-7" src={shild} alt="Secure icon" />
            <b>Secure</b>
          </div>
          <div className="self-stretch relative text-mid font-roboto text-gray-300">
            Our strict verification processes and encryption ensure the utmost
            security for your sensitive communication.
          </div>
        </div>
        <div className="flex-[0.9598] flex flex-col items-start justify-start pt-[23px] pb-11 pr-[35px] pl-6 box-border gap-[29px] min-w-[270px] max-w-full mq450:flex-1">
          <div className=" flex gap-2 items-center relative tracking-[-0.5px] leading-[28px] min-w-[81px] mq450:text-lgi mq450:leading-[22px]">
            <img className=" w-7 h-7" src={checkCircle} alt="Reliable icon" />
            <b>Reliable</b>
          </div>
          <div className="self-stretch relative text-lg leading-[24px] font-roboto text-gray-300">
            ePMS ensures that your messages reach your incarcerated relatives
            promptly and reliably.
          </div>
        </div>
        <div className="flex-[0.9885] flex flex-col items-start justify-start pt-[23px] pb-11 pr-[26px] pl-6 box-border gap-[29px] min-w-[270px] max-w-full mq450:flex-1">
          <div className=" flex gap-2 items-center relative tracking-[-0.5px] leading-[28px] min-w-[81px] mq450:text-lgi mq450:leading-[22px]">
            <img className=" w-7 h-7" src={fileText} alt="Compliance icon" />
            <b>Compliance</b>
          </div>
          <div className="self-stretch relative text-lg leading-[24px] font-roboto text-gray-300">
            We understand the importance of adherence to prison regulations.
            ePMS ensures that every email is thoroughly reviewed and approved by
            prison administrators.
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start pt-[23px] pb-11 pr-[22.4px] pl-6 box-border gap-[29px] min-w-[270px] max-w-full">
          <div className=" flex gap-2 items-center relative tracking-[-0.5px] leading-[28px] min-w-[81px] mq450:text-lgi mq450:leading-[22px]">
            <img className=" w-7 h-7" src={monitor} alt="Interface icon" />
            <b>Interface</b>
          </div>
          <div className="self-stretch relative text-lg font-roboto text-gray-300">
            Our platform is designed with simplicity in mind, making it easy for
            users to compose and send messages.
          </div>
        </div>
        <div className="flex-[0.9598] flex flex-col items-start justify-start pt-[23px] pb-11 pr-[35px] pl-6 box-border gap-[29px] min-w-[270px] max-w-full mq450:flex-1">
          <div className=" flex gap-2 items-center relative tracking-[-0.5px] leading-[28px] min-w-[81px] mq450:text-lgi mq450:leading-[22px]">
            <img className=" w-7 h-7" src={creditCard} alt="Pricing icon" />
            <b>Cost efficient</b>
          </div>
          <div className="self-stretch h-[84px] relative text-lg font-roboto text-gray-300 flex items-center">
            ePMS offers transparent and fair pricing models, ensuring
            affordability for users while maintaining the quality and security
            of our service.
          </div>
        </div>
        <div className="flex-[0.9885] flex flex-col items-start justify-start pt-[23px] pb-11 pr-[26px] pl-6 box-border gap-[29px] min-w-[270px] max-w-full mq450:flex-1">
          <div className=" flex gap-2 items-center relative tracking-[-0.5px] leading-[28px] min-w-[81px] mq450:text-lgi mq450:leading-[22px]">
            <img className=" w-7 h-7" src={lifeBuoy} alt="Support icon" />
            <b>Support</b>
          </div>
          <div className="self-stretch h-[84px] relative text-lg font-roboto text-gray-300 flex items-center">
            Our team is ready to assist you, providing timely and helpful
            responses to any inquiries or concerns you may have.
          </div>
        </div>
      </div>
      <div className="md:flex gap-[48px] md:max-w-full">
        <div className="md:w-[440px] md:text-left">
          <p className="text-[32px] md:text-[48px] font-bold">Bringing Families Together</p>
          <p className="text-[18px]">
            We take pride in our abilities to connect families no matter what.
          </p>
        </div>
        <img
          className="max-w-full overflow-hidden object-contain w-[570px]"
          loading="lazy"
          alt=""
          src={appScreenshot}
        />
      </div>
      <div className="md:flex gap-[114px] items-center text-center">
        <img
          className="md:w-[550px] max-w-full relative overflow-hidden shrink-0"
          alt=""
          src={all_integrations}
        />
        <div className="md:w-[465.05px] md:text-left">
          <p className="text-[32px] md:text-[48px] font-bold">
            Facilitating Fair Communication
          </p>
          <p className="text-[18px]">
            Our goal is not just to connect families with their loved ones under
            detention but to ensure fair and compliant communication, between
            them.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent1;
