import familyMember from "../assets/icons/familyMember.svg";
import creditCard from "../assets/icons/credits.svg";
import docs from "../assets/icons/docs.svg";
import delivery from "../assets/icons/delivery.svg";
import ThirdFeatureMessage from "./ThirdFeatureMessage";

const FrameComponent2 = () => {
  return (
    <section className="font-inter my-10">
      <div className="hidden md:block">
        <p className=" text-center text-[48px] font-bold">Key Features</p>
        <div className="flex justify-center">
          <div className="">
            <div className="flex">
              <div>
                <img src={familyMember} alt="FamilyMember icon" />
              </div>
              <img
                className="w-[190.8px] h-[21.3px]"
                loading="lazy"
                alt=""
                src="/arc-2.svg"
              />
            </div>
          </div>
          <div className="flex">
            <div>
              <img src={creditCard} alt="CreditCard icon" />
            </div>
            <img
              className="w-[190.8px] h-[21.3px] mt-14"
              loading="lazy"
              alt=""
              src="/arc-1.svg"
            />
          </div>
          <div className="flex">
            <div>
              <img src={docs} alt="FamilyMember icon" />
            </div>
            <img
              className="w-[190.8px] h-[21.3px]"
              loading="lazy"
              alt=""
              src="/arc-3.svg"
            />
          </div>
          <div className="flex">
            <div>
              <img src={delivery} alt="FamilyMember icon" />
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-[23px] text-center">
          <div className="w-[245px]">
            <p className=" text-[12px] text-[#06DDF9] font-medium">
              Add a family member to the app
            </p>
            <p className="text-[11px] font-urbanist">
              Find your family member(s) and add them for easy
              communication.
            </p>
          </div>
          <div className="w-[245px]">
            <p className=" text-[12px] text-[#06DDF9] font-medium">
              Add credits to the app
            </p>
            <p className="text-[11px] font-urbanist">
              Add credits to your wallet through a payment method of your choice
              and spend it on your loved ones.
            </p>
          </div>
          <div className="w-[245px]">
            <p className=" text-[12px] text-[#06DDF9] font-medium">
              Scan handwritten letter and attach photographs
            </p>
            <p className="text-[11px] font-urbanist">
              Write letters digitally, scan pictures, and handwrite notes; give
              them a personal touch.
            </p>
          </div>
          <div className="w-[245px]">
            <p className=" text-[12px] text-[#06DDF9] font-medium">
              Instant Delivery
            </p>
            <p className="text-[11px] font-urbanist">
              Get the printout delivered in no time by the prison admin. Check
              the status of your letter on the app.
            </p>
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <p className="text-center text-[32px] md:text-[48px] font-bold">Key Features</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 justify-items-center">
          <div className="w-[245px] flex flex-col items-center">
            <img src={familyMember} alt="FamilyMember icon" />
            <p className=" text-[12px] text-[#06DDF9] font-medium">
              Add a family member to the app
            </p>
            <p className="text-[11px] text-center font-urbanist">
              Find your family member(s) and add them for easy
              communication.
            </p>
          </div>
          <div className="w-[245px] flex flex-col items-center">
            <img src={creditCard} alt="FamilyMember icon" />
            <p className=" text-[12px] text-[#06DDF9] font-medium">
              Add credits to the app
            </p>
            <p className="text-[11px] text-center font-urbanist">
              Add credits to your wallet through a payment method of your choice
              and spend it on your loved ones.
            </p>
          </div>
          <div className="w-[245px] flex flex-col items-center">
            <img src={docs} alt="FamilyMember icon" />
            <p className=" text-[12px] text-[#06DDF9] font-medium">
              Scan handwritten letter and attach photographs
            </p>
            <p className="text-[11px] text-center font-urbanist">
              Write letters digitally, scan pictures, and handwrite notes; give
              them a personal touch.
            </p>
          </div>
          <div className="w-[245px] flex flex-col items-center">
            <img src={delivery} alt="FamilyMember icon" />
            <p className=" text-[12px] text-[#06DDF9] font-medium">
              Instant Delivery
            </p>
            <p className="text-[11px] text-center font-urbanist">
              Get the printout delivered in no time by the prison admin. Check
              the status of your letter on the app.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent2;
