import Header from "../components/Header";
import FrameComponent2 from "../components/FrameComponent2";
import FrameComponent1 from "../components/FrameComponent1";
import ContectUs from "../components/ContactUs";
import { useRef } from "react";

const Home = () => {
  const contectUsSectionRef = useRef(null);
  return (
    <div className="w-full relative bg-neutral-100 overflow-hidden flex flex-col justify-start gap-[6.6px] tracking-[normal]">
      <Header contectUsSectionRef={contectUsSectionRef} />
      <section className="self-stretch md:flex gap-[64px] md:items-start md:justify-start px-[72px] py-[64px] bg-[#F9FAFB] max-w-full text-center text-29xl text-darkturquoise font-inter">
        <div className="md:min-w-[320px] flex items-center text-left justify-center pb-4 my-auto">
          <div className="text-[32px] md:text-[48px]">
            <span>WHO</span>
            <span className="text-black1">
              <span className="text-[42.2px]">{` `}</span>
              <span>WE ARE</span>
            </span>
          </div>
        </div>
        <div className="text-lg text-black1 text-left  max-w-full">
          <p className="m-0">
            We provide an easy and affordable way to send letters, drawings, and
            photographs, the service helps families stay connected regardless of
            distance or financial constraints.
          </p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">
            The app eliminates third party handling and the need for stamps and
            trips to the post office, making it an accessible and user-friendly
            option.
          </p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">
            For less than the cost of an envelope and a stamp and much quicker
            than regular mail or traditional mail scanning, families can send a
            personal handwritten letter or drawing and up to three photos that
            their loved one can hold on to and treasure.
          </p>
        </div>
      </section>
      <FrameComponent2 />
      <section className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[34.1px] box-border max-w-full">
        <div className="w-[1140px] flex flex-col items-start justify-start gap-[99.9px] max-w-full mq750:gap-[25px_99.9px] mq1125:gap-[50px_99.9px]">
          <FrameComponent1 />
          <ContectUs contectUsSectionRef={contectUsSectionRef} />
        </div>
      </section>
      <footer className="self-stretch bg-darkturquoise flex flex-row items-start justify-center py-[15px] pr-5 pl-[53px] text-left text-[12px] text-neutral-100 font-inter mq1125:pl-[26px] mq1125:box-border">
        <div className="relative leading-[26px]">
          Copyright - Property of the Prison Mail Service. None of the contents
          in this document can be copied or adapted without the express written
          permission of the Prison Mail Service.
        </div>
      </footer>
    </div>
  );
};

export default Home;
